export async function getMediaRequest(filename) {
  const request = new Request(
    `${process.env.VUE_APP_BASE_URL}/media/${filename}`,
    {
      credentials: "include",
    }
  );
  const response = await (await fetch(request)).blob();
  return URL.createObjectURL(response);
}
