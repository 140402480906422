<template>
  <div class="theTimeline__container">
    <div class="theTimeline__header">
      <button class="theTimeline__addEventButton" @click="showAddEventModal">
        添加回忆
      </button>
    </div>
    <div class="theTimeline__timeline">
      <event-item
        v-for="(event, index) in events"
        :key="`${event.title}${event.datetime}`"
        :title="event.title"
        :content="event.content"
        :date="event.datetime"
        :timezone="event.timezone"
        :media="event.files"
        :isFirst="index == 0"
        :isLast="index == events.length - 1"
      />
    </div>

    <!-- <div class="theTimeline__title">
      <span class="theTimeline__blue">我</span
      >和我的<br />上知天文,下知地理,<br />博古通今,才高八斗,<br />智商高情商高,<br />要跟他搏斗他还长得高<br />的<span
        class="theTimeline__pink"
        >老婆
      </span>
    </div> -->
  </div>

  <add-event-modal
    v-if="isModalVisible"
    :on-close-modal="closeAddEventModal"
    :on-submit="refreshEvents"
  />
</template>

<script setup scoped>
import EventItem from "./EventItem.vue";
import { getEvents } from "@/api/events";
import { onMounted, ref } from "vue";
import AddEventModal from "./AddEventModal.vue";

const events = ref([]);

onMounted(async () => {
  events.value = await getEvents();
});

const isModalVisible = ref(false);

function showAddEventModal() {
  isModalVisible.value = true;
}

function closeAddEventModal() {
  isModalVisible.value = false;
}

async function refreshEvents() {
  closeAddEventModal();
  events.value = await getEvents();
}
</script>

<style lang="scss" scoped>
.theTimeline__container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 90%;
  width: 90%;

  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;

  .theTimeline__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    margin: 8px 16px;

    .theTimeline__addEventButton {
      border: 1px black solid;
      border-radius: 12px;
      padding: 8px;

      &:hover {
        cursor: pointer;
      }
    }
  }
  .theTimeline__timeline {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 16px;

    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }

  .theTimeline__title {
    text-orientation: upright;
    writing-mode: vertical-rl;
    font-size: 32px;

    .theTimeline__pink {
      color: rgb(206, 133, 190);
    }

    .theTimeline__blue {
      color: #4747bf;
    }
  }
}
</style>
