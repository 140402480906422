<template>
  <div class="app__theAuthenticator" v-if="!isAuthenticated">
    <the-authenticator :log-in="authenticate" />
  </div>
  <template v-else>
    <div class="app__theNavBar">
      <the-nav-bar />
    </div>
    <div class="app__content">
      <the-timeline />
    </div>
  </template>
</template>

<script setup>
import theTimeline from "./components/theTimeline.vue";
import TheNavBar from "./components/TheNavBar.vue";
import TheAuthenticator from "./components/TheAuthenticator.vue";
import { ref } from "vue";

const isAuthenticated = ref(false);

function authenticate() {
  isAuthenticated.value = true;
}
</script>

<style>
@font-face {
  font-family: "思源宋体";
  src: url("./assets/fonts/思源宋体.woff2");
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: "思源宋体", "ZCOOL KuaiLe", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url("./assets/sunflower_field.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
}

.app__theAuthenticator {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
}

.app__theNavBar {
  height: 100px;
  position: relative;
  /* z-index: 999; */
}

.app__content {
  display: flex;
  justify-content: center;
  height: calc((100vh - 100px));
}
</style>
