<template>
  <div class="eventItem__container" :class="eventItemClass">
    <div class="eventItem__eventIcon">
      <event-icon isFirst isLast />
    </div>
    <div class="eventItem__main">
      <div @click="toggleContent" class="eventItem__header">
        {{ formattedDate }} - {{ title }}
      </div>
    </div>
  </div>
  <event-modal v-if="isModalVisible" :on-close-modal="toggleContent">
    <template #title> {{ formattedDate }} - {{ title }} </template>
    <template #content>
      <div v-html="content"></div>
    </template>
    <template #media>
      <base-media-viewer v-if="media.length > 0" :media="media" />
    </template>
  </event-modal>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import EventIcon from "./EventIcon.vue";
import BaseMediaViewer from "./BaseMediaViewer.vue";
import EventModal from "./EventModal.vue";

const props = defineProps({
  date: {
    type: String,
    required: true,
  },
  timezone: {
    type: String,
    requdired: false,
  },
  title: {
    required: true,
    type: String,
  },
  content: {
    type: String,
    default: "",
  },
  media: {
    type: Array,
    required: false,
  },
  images: {
    type: Array,
    required: false,
  },
  audio: {
    type: Array,
    required: false,
  },
  videos: {
    type: Array,
    required: false,
  },
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
});

const isModalVisible = ref(false);
const formattedDate = computed(() => {
  const localDatetime = new Date(props.date);

  if (props.date == "") return "∞";

  if (props.timezone) {
    const convertedDatetime = localDatetime.toLocaleString("zh-CN", {
      timeZone: props.timezone,
    });
    const dateStringSplit = convertedDatetime.split(" ")[0].split(["/"]);
    return `${dateStringSplit[0]}年 ${dateStringSplit[1]}月 ${dateStringSplit[2]}日`;
  }

  return `${localDatetime.getFullYear()}年 ${
    localDatetime.getMonth() + 1
  }月 ${localDatetime.getDate()}日`;
});

function toggleContent() {
  if (props.content.length > 0 || props.media.length > 0)
    isModalVisible.value = !isModalVisible.value;
}

const eventItemClass = computed(() => {
  if (props.isFirst) return "eventItem__first";
  if (props.isLast) return "eventItem__last";
  return "eventItem__middle";
});
</script>

<style lang="scss" scoped>
.eventItem__container {
  display: flex;
  gap: 12px;
  position: relative;
  height: 100%;

  .eventItem__main {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    padding: 4px;
    border: 1px black solid;
    border-radius: 8px;
    background-color: #d0ddd0;

    .eventItem__header {
      display: flex;
      height: 100%;
      font-size: 18px;

      &:hover {
        cursor: pointer;
      }
    }

    .eventItem__content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
</style>
