<template>
  <div class="baseAudioPlayer__container">
    <div v-if="currentPlaying.title" class="baseAudioPlayer__title">
      {{ currentPlaying.title }} - {{ currentPlaying.author }}
    </div>

    <template v-if="isCustomPlayer">
      <audio
        ref="mediaRef"
        :src="currentPlaying.path"
        @loadeddata="initializePlayer"
        @timeupdate="updatePosition"
        @ended="onEnd"
        class="baseAudioPlayer__audio"
      ></audio>
      <div class="baseAudioPlayer__content">
        <button
          type="button"
          class="baseAudioPlayer__playButton"
          @click="playMedia"
        >
          <img :src="mediaButtonIcon" />
        </button>
        <div class="baseAudioPlayer__timer">
          {{ mediaCurrentPosition }} / {{ mediaDuration }}
        </div>
      </div>
    </template>
    <template v-else>
      <audio
        ref="mediaRef"
        :src="currentPlaying.url"
        @loadeddata="initializePlayer"
        @timeupdate="updatePosition"
        @ended="onEnd"
        class="baseAudioPlayer__audio"
        controls
      ></audio>
    </template>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";

const props = defineProps({
  autoplay: {
    type: Boolean,
    default: false,
  },
  playlist: {
    type: Array,
    required: true,
  },
  loop: {
    type: Boolean,
    default: false,
  },

  isCustomPlayer: {
    type: Boolean,
    default: false,
  },
});

const mediaRef = ref(null);
const mediaDuration = ref(formatDuration(0));
const mediaButtonIcon = ref(require("../assets/icons/play_fill.svg"));
const mediaCurrentPosition = ref(formatDuration(0));

const currentPlayingIndex = ref(0);
const currentPlaying = computed(
  () => props.playlist[currentPlayingIndex.value]
);

function playMedia() {
  if (mediaRef.value.paused) {
    mediaRef.value.play();
    mediaButtonIcon.value = require("../assets/icons/pause.svg");
    return;
  }
  mediaRef.value.pause();
  mediaButtonIcon.value = require("../assets/icons/play_fill.svg");
}

function initializePlayer() {
  mediaDuration.value = formatDuration(Math.round(mediaRef.value.duration));
  if (props.autoplay) {
    mediaRef.value.play();
    mediaButtonIcon.value = require("../assets/icons/pause.svg");
  }
}

async function playNextTrack() {
  currentPlayingIndex.value =
    (currentPlayingIndex.value + 1) % props.playlist.length;
  await mediaRef.value.load();

  mediaRef.value.play();
}

function formatDuration(duration) {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor((duration - hours * 60 * 60) / 60);
  const seconds = Math.floor(duration - hours * 60 * 60 - minutes * 60);
  return (
    (hours == 0 ? "" : `${hours}:`) +
    `${minutes}:${seconds.toString().padStart(2, "0")}`
  );
}

function updatePosition() {
  mediaCurrentPosition.value = mediaRef.value
    ? formatDuration(mediaRef.value.currentTime)
    : null;
}

function onEnd() {
  if (props.loop) {
    playNextTrack();
    return;
  }
  mediaButtonIcon.value = require("../assets/icons/play_fill.svg");
}
</script>

<style lang="scss" scoped>
.baseAudioPlayer__title {
  display: flex;
  justify-content: flex-end;
}
.baseAudioPlayer__content {
  display: flex;
  align-items: center;
  gap: 8px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(194, 191, 191);
    border-radius: 50%;
    width: 30px;
    height: 30px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
