export async function getEvents() {
  const request = new Request(`${process.env.VUE_APP_BASE_URL}/events`, {
    credentials: "include",
  });

  const response = await (await fetch(request)).json();
  return response;
}

export async function addEvent(eventInfo) {
  const request = new Request(`${process.env.VUE_APP_BASE_URL}/events`, {
    method: "POST",
    credentials: "include",
    body: eventInfo,
  });
  const response = await fetch(request);
  return response;
}
