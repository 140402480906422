<template>
  <base-modal :is-closeable="false">
    <template #content>
      <form @submit.prevent="authenticate" class="theAuthenticator__form">
        <input
          type="text"
          v-model="username"
          id="theAuthenticator__username"
          placeholder="用户名"
          required
        />
        <input
          type="password"
          v-model="password"
          id="theAuthenticator__password"
          placeholder="密码"
          required
        />
        <button type="submit">提交</button>
      </form>
    </template>
  </base-modal>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { logInRequest } from "../api/usersApi";
import BaseModal from "./BaseModal.vue";

const props = defineProps({
  logIn: {
    type: Function,
    required: true,
  },
});

const username = ref("");
const password = ref("");

async function authenticate() {
  const status = await logInRequest(username.value, password.value);
  if (status == 200) props.logIn();
}
</script>

<style lang="scss" scoped>
.theAuthenticator__modal {
  width: 50%;
}
.theAuthenticator__form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 200px;

  input {
    font-size: 20px;
  }
  button {
    background-color: rgb(171, 212, 171);
    border: none;
    padding: 8px;

    &:hover {
      background-color: rgb(50, 151, 50);
      color: white;
      cursor: pointer;

      transition: background-color 0.3s, color 0.3s;
    }
  }
}
</style>
