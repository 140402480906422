<template>
  <div class="eventIcon__container" :class="pathSegmentClass">
    <img src="../assets/icons/sunflower.svg" class="eventIcon__main" />
  </div>
</template>

<script setup scoped>
import { defineProps, computed } from "vue";
const props = defineProps({
  isFirst: {
    type: Boolean,
    default: false,
  },
  isLast: {
    type: Boolean,
    default: false,
  },
});

const pathSegmentClass = computed(() => {
  if (props.isFirst) return "pathSegment__beginning";
  if (props.isLast) return "pathSegment__end";
  return "pathSegment__middle";
});
</script>

<style lang="scss" scoped>
.eventIcon__container {
  .eventIcon__main {
    fill: rgb(218, 105, 193);
    width: 32px;
    z-index: 1;
  }
}
</style>
