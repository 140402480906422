export async function logInRequest(username, password) {
  const request = new Request(
    `${process.env.VUE_APP_BASE_URL}/users/authenticate`,
    {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username, password: password }),
    }
  );
  const response = await fetch(request);

  return response.status;
}
