<template>
  <base-modal :on-close-modal="onCloseModal">
    <template #title>添加回忆</template>
    <template #content>
      <form class="addEventModal__form" @submit.prevent="submitForm">
        <label for="addEventModal__form__title">标题</label>
        <input
          id="addEventModal__form__title"
          type="text"
          v-model="eventTitle"
          required
        />

        <label for="addEventModal__form__date">日期</label>
        <input
          id="addEventModal__form__date"
          type="datetime-local"
          v-model="eventDatetime"
          required
        />

        <label for="addEventModal__form__text">内容</label>
        <textarea id="addEventModal__form__text" v-model="eventText"></textarea>

        <label for="addEventModal__form__media">上传图片/视频/音频</label>
        <input
          id="addEventModal__form__media"
          type="file"
          multiple
          @change="addFiles"
        />

        <button type="submit">提交</button>
      </form>
    </template>
  </base-modal>
</template>

<script setup>
import { defineProps, ref } from "vue";
import BaseModal from "./BaseModal.vue";
import { addEvent } from "@/api/events";

const props = defineProps({
  onCloseModal: {
    type: Function,
    required: true,
  },
  onSubmit: {
    type: Function,
    required: true,
  },
});

const eventTitle = ref("");
const eventText = ref("");
const eventFiles = ref([]);
const eventDatetime = ref(getCurrentDatetimeString());

function addFiles(e) {
  eventFiles.value = e.target.files;
}

async function submitForm() {
  const formData = new FormData();

  formData.append("title", eventTitle.value);
  formData.append("content", eventText.value);
  formData.append("datetime", new Date(eventDatetime.value).toISOString());

  for (const file of eventFiles.value) {
    formData.append("files", file);
  }

  await addEvent(formData);
  props.onSubmit();
}

function getCurrentDatetimeString() {
  const datetime = new Date();
  const year = datetime.getFullYear();
  const month = (datetime.getMonth() + 1).toString().padStart(2, "0");
  const date = datetime.getDate().toString().padStart(2, "0");
  const hour = datetime.getHours().toString().padStart(2, "0");
  const minute = datetime.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${date}T${hour}:${minute}`;
}
</script>

<style lang="scss" scoped>
.addEventModal__title {
  font-weight: bold;
  font-size: 16px;
  padding: 12px 0;
}

.addEventModal__form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: center;
}
</style>
