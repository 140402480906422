<template>
  <div class="baseMediaViewer__container">
    <div
      class="baseMediaViewer__arrow"
      :class="multipleMediaClass"
      @click="previousMedia"
    >
      <img src="../assets/icons/arrow-left-bold.svg" />
    </div>
    <div class="baseMediaViewer__content">
      <img v-if="isImage" :src="currentMedia.url" />
      <base-audio-player v-else-if="isAudio" :playlist="[currentMedia]" />
      <base-video-player
        class="baseMediaViewer__media"
        v-else-if="isVideo"
        :playlist="[currentMedia]"
      />
    </div>
    <div
      class="baseMediaViewer__arrow"
      :class="multipleMediaClass"
      @click="nextMedia"
    >
      <img src="../assets/icons/arrow-right-bold.svg" />
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, computed, onMounted } from "vue";
import BaseAudioPlayer from "./BaseAudioPlayer.vue";
import BaseVideoPlayer from "./BaseVideoPlayer.vue";
import { getMediaRequest } from "@/api/media";

const props = defineProps({
  media: {
    type: Array,
    required: true,
  },
});

const currentMedia = ref(null);
const currentMediaIndex = ref(0);
const currentMediaExtension = ref(null);
const processedMedia = ref([]);

const multipleMediaClass = computed(() =>
  processedMedia.value.length <= 1 ? "baseMediaViewer_inactiveArrows" : ""
);

const isImage = computed(
  () =>
    currentMediaExtension.value == "jpg" || currentMediaExtension.value == "png"
);

const isVideo = computed(() => currentMediaExtension.value == "mp4");

const isAudio = computed(() => currentMediaExtension.value == "mp3");

onMounted(async () => {
  processedMedia.value = props.media.map(async (medium) => {
    return {
      filename: medium,
      url: await getMediaRequest(medium),
    };
  });
  currentMedia.value = await processedMedia.value[currentMediaIndex.value];
  currentMediaExtension.value = currentMedia.value.filename.split(".")[1];
});

async function nextMedia() {
  currentMediaIndex.value =
    (currentMediaIndex.value + 1) % processedMedia.value.length;
  changeMedia();
}

async function previousMedia() {
  currentMediaIndex.value =
    Math.abs(currentMediaIndex.value - 1) % processedMedia.value.length;
  changeMedia();
}

async function changeMedia() {
  currentMedia.value = await processedMedia.value[currentMediaIndex.value];
  currentMediaExtension.value = currentMedia.value.filename.split(".")[1];
}
</script>

<style lang="scss" scoped>
.baseMediaViewer__container {
  display: flex;
  justify-content: space-between;

  .baseMediaViewer__content {
    img {
      width: 100%;
      max-height: 400px;
    }
  }

  .baseMediaViewer__arrow {
    display: flex;
    align-items: center;

    img {
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .baseMediaViewer_inactiveArrows {
    &:hover {
      cursor: initial;
    }

    img {
      opacity: 0.1;
    }
  }
}
</style>
