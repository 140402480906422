<template>
  <div class="baseVideoPlayer__container">
    <video
      ref="mediaRef"
      :src="currentPlaying.url"
      @loadeddata="initializePlayer"
      @timeupdate="updatePosition"
      @ended="onEnd"
      class="baseVideoPlayer__video"
      controls
    ></video>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";

const props = defineProps({
  autoplay: {
    type: Boolean,
    default: false,
  },
  playlist: {
    type: Array,
    required: true,
  },
  loop: {
    type: Boolean,
    default: false,
  },
});

const mediaRef = ref(null);
const mediaDuration = ref(formatDuration(0));
const mediaButtonIcon = ref(require("../assets/icons/play_fill.svg"));
const mediaCurrentPosition = ref(formatDuration(0));

const currentPlayingIndex = ref(0);
const currentPlaying = computed(
  () => props.playlist[currentPlayingIndex.value]
);

function initializePlayer() {
  mediaDuration.value = formatDuration(Math.round(mediaRef.value.duration));
  if (props.autoplay) {
    mediaRef.value.play();
    mediaButtonIcon.value = require("../assets/icons/pause.svg");
  }
}

async function playNextTrack() {
  currentPlayingIndex.value =
    (currentPlayingIndex.value + 1) % props.playlist.length;
  await mediaRef.value.load();

  mediaRef.value.play();
}

function formatDuration(duration) {
  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor((duration - hours * 60 * 60) / 60);
  const seconds = Math.floor(duration - hours * 60 * 60 - minutes * 60);
  return (
    (hours == 0 ? "" : `${hours}:`) +
    `${minutes}:${seconds.toString().padStart(2, "0")}`
  );
}

function updatePosition() {
  mediaCurrentPosition.value = formatDuration(mediaRef.value.currentTime);
}

function onEnd() {
  if (props.loop) {
    playNextTrack();
    return;
  }
  mediaButtonIcon.value = require("../assets/icons/play_fill.svg");
}
</script>

<style lang="scss" scoped>
video {
  height: 100%;
  width: 100%;
}
</style>
